import { mapState } from 'vuex';
import ResizeMixin from '@/layout/mixin/ResizeHandler';
const trustTitle = ['为什么可以相信诺辉健康？', '', ''];
const mapText = [{
  subTitle: ['诺辉健康发布中国首个癌症早筛前瞻性大规模多中心注册临床试验结果', '', ''],
  content: ['2020年9月25日，中国首个癌症早筛前瞻性大规模多中心注册临床试验（Clear-C）由国家药品监督管理局监督和指导，采用了在8家大型三甲医院共同开展，历时16个月，入组人数5881例。', '', ''],
  href: 'http://www.newhorizonbio.com/#/articleDetail?id=140'
}, {
  subTitle: ['常卫清®获得目前唯一通过国家药品监督管理局批准的消化道癌症早筛产品注册证', '', ''],
  content: ['2020年11月9日，国家药品监督管理局（NMPA）批准常卫清®的创新三类医疗器械注册申请，这是国家药品监督管理局批准的中国首个也是目前唯一的消化道癌症早筛产品注册证。', '', ''],
  href: 'http://www.newhorizonbio.com/#/articleDetail?id=142'
}, {
  subTitle: ['常卫清®技术进入《中国结直肠癌筛查与早诊早治指南（2020，北京）》', '', ''],
  content: ['2021年1月15日，《中华肿瘤杂志》发布《中国结直肠癌筛查与早诊早治指南（2020，北京）》，明确推荐多靶点粪便FIT-DNA联合检测技术为肠癌早筛的重要手段之一。', '', ''],
  href: 'http://www.newhorizonbio.com/#/articleDetail?id=148'
}, {
  subTitle: ['常卫清®技术被纳入2021版CSCO结直肠癌诊疗指南', '', ''],
  content: ['2021年4月2323-24日在北京召开的CSCO指南大会上，“粪便FIT-DNA检测”首次被纳入《2021版CSCO结直肠癌诊疗指南》推荐的结直肠癌筛查路径，作为一般风险人群筛查的III级推荐。', '', ''],
  href: 'http://www.newhorizonbio.com/#/articleDetail?id=165'
}, {
  subTitle: ['诺辉健康发布苷证清™预研数据', '', ''],
  content: ['2021年9月27日，诺辉健康在2021年中国临床肿瘤学会(“CSCO”)学术年会上发布全球首个“全生命法则”肝癌早筛分子检测苷证清™的预研数据结果。', '', ''],
  href: 'http://www.newhorizonbio.com/#/articleDetail?id=186'
}, {
  subTitle: ['幽幽管®获批国家药监局幽门螺杆菌消费者自测第一证', '', ''],
  content: ['2021年12月31日，诺辉健康旗下幽门螺杆菌检测产品幽幽管®获得国家药监局批准的中国首个适用于“消费者自测”的幽门螺杆菌检测产品。', '', ''],
  href: 'http://www.newhorizonbio.com/#/articleDetail?id=198'
}, {
  subTitle: ['常卫清®技术被纳入《中国肿瘤整合诊治指南（CACA）》', '', ''],
  content: ['2022年4月17日，多靶点粪便FIT-DNA检测技术被纳入《中国肿瘤整合诊治指南（CACA）》结直肠癌部分，成为指南中唯一获得推荐的结直肠分子筛查技术。', '', ''],
  // href: 'https://mp.weixin.qq.com/s/r1CZzBG47pEs8TMOrQGSLQ'
  href: 'https://www.newhorizonbio.com/#/articleDetail?id=269'
}, {
  subTitle: ['诺辉健康发布宫证清™预临床数据', '', ''],
  content: ['2022年11月12日，诺辉健康在第二十五届全国临床肿瘤学大会暨2022年CSCO学术年会期间首次发布宫颈癌早筛产品宫证清预临床数据，并正式亮相自主研发的STAR-Seq多组学技术平台。', '', ''],
  href: 'http://www.newhorizonbio.com/#/articleDetail?id=232'
}, {
  subTitle: ['诺辉健康发布首份常卫清®用户数据报告', '', ''],
  content: ['2023年4月15日，诺辉健康发布首份常卫清用户检测数据报告，2021年至2022年，诺辉健康共计服务超50万常卫清用户完成检测。', '', ''],
  href: 'http://www.newhorizonbio.com/#/articleDetail?id=259'
}];
export default {
  name: 'TrustNh',
  mixins: [ResizeMixin],
  data() {
    return {
      trustTitle,
      mapText
    };
  },
  computed: {
    ...mapState({
      device: state => state.device,
      language: state => state.language
    }),
    bannerHeight() {
      return this.device == 'pc' ? '10.825rem' : '100vh';
    }
  },
  mounted() {
    console.log('🚀 ~ device:', this.device, this.language);
  }
};